<template>
  <div
    v-if="show"
    class="absolute bottom-5 left-5 right-5 border-l-4 p-4"
    role="alert"
    :class="getVariantClasses()"
  >
    <p class="font-bold">{{ title }}</p>
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    variant: {
      type: String,
      default: "success",
    },
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String,
      default: "Título padrão",
    },
    message: {
      type: String,
      default: "Mensagem padrão",
    },
  },
  data() {
    return {
      variants: {
        success: ["bg-green-200", "border-green-600", "text-green-600"],
        danger: ["bg-red-200", "border-red-600", "text-red-600"],
        alert: ["bg-yellow-200", "border-yellow-600", "text-yellow-600"],
      },
    };
  },

  methods: {
    getVariantClasses() {
      return this.variants[this.variant];
    },
  },
};
</script>

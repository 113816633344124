<template>
  <div
    class="
      min-h-screen
      flex
      items-center
      justify-center
      bg-gray-50
      py-12
      px-4
      sm:px-6
      lg:px-8
    "
  >
    <div class="max-w-md w-full space-y-8">
      <div>
        <img
          class="mx-auto h-64 w-auto"
          src="@/assets/img/logo_principal.svg"
          alt="Escola Araruna Anare"
        />
        <h2
          class="
            mt-6
            text-center text-3xl
            font-sans font-extrabold
            text-gray-900
          "
        >
          Entre com a sua conta
        </h2>
        <!-- <p class="mt-2 text-center text-sm text-gray-600">
          Or
          {{ " " }}
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            start your 14-day free trial
          </a>
        </p> -->
      </div>
      <form class="mt-8 space-y-6" v-on:submit.prevent="onLogin">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">Email</label>
            <input
              id="email-address"
              v-model="email"
              name="email"
              type="email"
              autocomplete="email"
              required="true"
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                font-sans
              "
              placeholder="Email"
            />
          </div>
          <div>
            <label for="password" class="sr-only">Senha</label>
            <input
              id="password"
              v-model="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required="true"
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-b-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                font-sans
              "
              placeholder="Senha"
            />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input
              id="remember-me"
              v-model="rememberMe"
              name="remember-me"
              type="checkbox"
              class="
                h-4
                w-4
                text-indigo-600
                focus:ring-indigo-500
                border-gray-300
                rounded
              "
            />
            <label
              for="remember-me"
              class="ml-2 block text-sm text-gray-900 font-sans"
            >
              Lembrar meu email
            </label>
          </div>

          <!-- <div class="text-sm">
            <a
              href="#"
              class="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Forgot your password?
            </a>
          </div> -->
        </div>

        <div>
          <button
            type="submit"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-indigo-600
              hover:bg-indigo-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
              font-sans
            "
            @click="onLogin"
          >
            <span v-if="!logginIn">Entrar</span>
            <svg
              v-if="logginIn"
              class="animate-spin ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </button>
        </div>
      </form>
    </div>
  </div>
  <Alert
    :show="showErrorAlert"
    variant="danger"
    title="Erro"
    message="Não foi possível realizar login"
  />
</template>

<script>
import Firebase from "@/firebase";
import Alert from "@/components/Alert.vue";
import router from "@/router";

const REMEMBER_EMAIL_KEY = "remember-email-key";

export default {
  name: "Home",
  components: {
    Alert,
  },
  data() {
    return {
      email: "",
      password: "",
      rememberMe: false,

      logginIn: false,
      showErrorAlert: false,
    };
  },
  mounted() {
    const rememberedEmail = localStorage.getItem(REMEMBER_EMAIL_KEY);

    if (rememberedEmail) {
      this.email = rememberedEmail;
      this.rememberMe = true;
    }
  },
  methods: {
    async onLogin() {
      this.logginIn = true;

      try {
        await Firebase.signInWithEmailAndPassword(this.email, this.password);

        if (this.rememberMe) {
          localStorage.setItem(REMEMBER_EMAIL_KEY, this.email);
        } else {
          localStorage.removeItem(REMEMBER_EMAIL_KEY);
        }

        router.replace("/");
      } catch (e) {
        this.onLoginError();
        console.error("Could not sign in with email and password.");
        console.error(e);
      } finally {
        this.logginIn = false;
      }
    },
    onLoginError() {
      this.showErrorAlert = true;
      setTimeout(() => (this.showErrorAlert = false), 6000);
    },
  },
};
</script>
